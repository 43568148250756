<!-- Finder de Grupos -->

<template>
  <div class="gruposF" v-if="loaded">
    
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:700px"> 

          <!-- <div v-html="info"></div> -->

          <!-- Filtro de Búsqueda -->         
          <div class="columna">
            <uiText
              style="width:70%;margin-top:10px"       
              v-model="txt"
              label="Búsqueda"
              @Enter="eventFiltro('buscar')">             
            </uiText>         
          </div>
    
          <!-- Grid -->             
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            dense
            height="440px"            
            

            @onEvent="execAccion">
         
              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btGridCfg"
                  @onEvent="execAccion">
                </baseBtraMto>
              </template>

              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>              
              </template>            
          </baseGridMD>           
        </div>
      </v-sheet>  

      <!-- Mto Grupos Funerarios -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>

    </div>    
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";   
  import gruposM from "@/components/gruposM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, gruposM },
    props: {},

    data() {
      return { 
        stIni: {
          api: "grupF",
          titulo:"Grupos Funerarios/Empresas", 
          name:"grupF",
          mView:'gruposM',
          pView:[]          
        },

        txt:''        
      };
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
    
        this.headers = [{
          name:[],
          fields:[    
            { text: "Acciones", value: "acciones", sortable: false, width: "15%", slot:true },            
            { text: "Nombre", value: "name", witdh: "100%" }
          ]}
        ];
        
        this.headerGrid= this.headerSet();    

        // le indico que el finder tiene un autoload con la función 'buscar'
        // al cargar el componente hago un recordGet          
        this.autoLoad= 'buscar';        
      },


      // gancho después de obtener los registros del GRID
      recordsGetAfter() {
        this.filtrar();
      },


      // entra al buscar por el nombre del grupo
      async filtrar() {          
        await this.stFiltrar(this.filtrarGrupo, {});
      },

      // filtrar por Grupo de funeraria
      filtrarGrupo(records) {          
        return records.filter(elem=> elem.name.toLowerCase().includes(this.txt.toLowerCase()));
      },

    
    }
  };
</script>
